import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

// スタイル定義
const useStyles = makeStyles(() => ({
  inputField: {
    borderRadius: 10,
    fontSize: '2em',
    border: `2px solid ${colors.borderColor}`,
    padding: '5px',
    width: '100%',
    boxShadow: '10px 10px 20px rgba(0,0,0,.04)',
    marginRight: 8,
    textAlign: 'center',
    color: `${colors.orange}`,
    fontFamily: "'aileronregular','ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体',sans-serif",
    letterSpacing: '-1px',
    [mq.mdOnly]: {
      fontSize: '1.8em',
    },
  },
}));

// プロパティの型定義
interface INumberInputProps {
  inputValue: number;
  min: number;
  max: number;
  step: number;
  decimalPrecision: number;
  onFocusLost: (value: number) => void;
  // 入力値を親に渡す関数
  /* eslint react/require-default-props: 0 */
  handleValueChange?: (value: number) => void;
}

const NumberInput: React.FC<INumberInputProps> = ({
  inputValue = 0,
  min = 0,
  max = 100,
  step = 0.01,
  decimalPrecision = 2,
  onFocusLost,
  handleValueChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>(inputValue.toString());

  // 数値のフォーマット処理
  const formatNumber = (num: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPrecision,
      maximumFractionDigits: decimalPrecision,
    });
    return formatter.format(num);
  };

  // 外部の `inputValue` が変わったときに呼ばれる関数
  const updateValue = (input: string) => {
    let num = parseFloat(input.replace(/[^\d.]/g, ''));
    num = isNaN(num) ? 0 : num;
    // 0以外でminより小さい値を許容しない
    if (num !== 0) num = Math.max(min, Math.min(num, max));
    num = Math.round(num / step) * step;
    const formattedValue = formatNumber(num);
    setValue(formattedValue);
  };

  // 全角数字、全角小数点を半角数字に変換する関数
  const toHalfWidth = (str: string) => {
    return str.replace(/[０-９．]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  };

  // ユーザーの入力やフォーカスが外れたときに呼ばれる関数
  const formatAndHandleValue = (originalInput: string) => {
    // 全角数字が含まれる場合は半角に変換
    const input = toHalfWidth(originalInput);

    updateValue(input); // `value` の更新のみ

    // 入力値を親に渡す
    if (handleValueChange) {
      handleValueChange(Number(input));
    }

    let num = parseFloat(input.replace(/[^\d.]/g, ''));
    num = isNaN(num) ? 0 : num;
    // 0以外でminより小さい値を許容しない
    if (num !== 0) num = Math.max(min, Math.min(num, max));
    num = Math.round(num / step) * step;

    if (onFocusLost) {
      onFocusLost(num); // コールバックを実行
    }
  };

  // 外部の `inputValue` が変わったときの useEffect
  useEffect(() => {
    updateValue(inputValue.toString());
  }, [inputValue]);

  // フォーカスが外れたときの処理
  const handleBlur = () => {
    formatAndHandleValue(value);
  };

  // 値が変更されたときの処理
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <input
      className={classes.inputField}
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={(e) => { if (e.key === 'Enter') handleBlur(); }}
    />
  );
};

export default NumberInput;
