import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Loader } from '@googlemaps/js-api-loader';

// Google Maps APIのロード状態を管理するためのState型
interface GoogleMapsLoaderState {
  isLoaded: boolean;
  loadError: string | null;
}

// Stateの初期値設定
const initialState: GoogleMapsLoaderState = {
  isLoaded: false,
  loadError: null,
};

// 環境変数からAPIキーを取得します。
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Google Maps APIを非同期にロードするThunkアクション。
export const loadGoogleMapsApi = createAsyncThunk(
  'googleMapsLoader/load',
  async (_, { rejectWithValue }) => {
    if (!apiKey) {
      // APIキーが見つからなかった場合はエラーを返します。
      return rejectWithValue('API key is not available.');
    }

    try {
      // 追加ライブラリとしてplacesおよびgeocodeを指定
      const loader = new Loader({
        apiKey: apiKey,
        version: 'quarterly', // 四半期ごとにコードが更新される
        libraries: ['places', 'visualization'], // コードに含める必要な追加のライブラリーを指定、ただし'map','geocode'はデフォルトで含まれる
      });
      // Google Maps APIのロード
      await loader.load();

      // 安全のため、APIがロードされた後のグローバルスコープに`google`オブジェクトがあることを確認
      if (typeof google === 'undefined' || !google.maps) {
        throw new Error('Google Maps API did not load correctly.');
      }

      // APIが正常にロードされたことを示す値を返す
      return true;
    } catch (error) {
      // APIロード中にエラーが発生した場合は、それをrejectします。
      return rejectWithValue('Google Maps API failed to load.');
    }
  },
);

// スライスの定義。
export const googleMapsLoaderSlice = createSlice({
  name: 'googleMapsLoader',
  initialState,
  reducers: { // この例ではreducersは使用しない。
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGoogleMapsApi.pending, (state) => {
        // APIロードの処理が開始された時の状態を設定。
        state.isLoaded = false;
        state.loadError = null;
      })
      .addCase(loadGoogleMapsApi.fulfilled, (state) => {
        // APIロードが完了した時の状態を設定。
        state.isLoaded = true;
      })
      .addCase(loadGoogleMapsApi.rejected, (state, action) => {
        // APIロードが失敗した時の状態を設定。
        state.isLoaded = false;
        state.loadError = action.payload as string;
      });
  },
});

// スライスのreducerをエクスポート。
export default googleMapsLoaderSlice.reducer;
